<template>
    <div>
        <div v-if="authUserPermission['export-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4 mt--7">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-4">
                                <h3 class="mb-0">{{ tt('export_data') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <base-input :label="tt('type')" :name="tt('type')">
                                    <el-select class="select-danger mb--5" v-model="inputType"
                                        :placeholder="tt('choose_type')" @change="changeType(inputType)">
                                        <el-option class="select-danger" value="End User" label="End User"></el-option>
                                        <el-option class="select-danger" value="Workbench"
                                            label="Workbench"></el-option>
                                        <el-option class="select-danger" value="Work Report"
                                            label="Work Report"></el-option>
                                        <el-option class="select-danger" value="Part Char Horizontal"
                                            label="Part Characteristic Value Horizontal"></el-option>
                                        <el-option class="select-danger" value="Part Char Horizontal Multiple"
                                            label="Part Characteristic Value Horizontal Multiple"></el-option>
                                        <el-option class="select-danger" value="Part Char Horizontal by File"
                                            label="Part Characteristic Value Horizontal by File"></el-option>
                                        <el-option class="select-danger" value="Mesc Characteristic"
                                            label="Mesc Characteristic"></el-option>
                                        <el-option class="select-danger" value="Generate Bom Report"
                                            label="Generate Bom Report"></el-option>
                                    </el-select>
                                </base-input>
                                <div v-if="inputType == 'Work Report'">
                                    <!-- <base-input :label="tt('catalog_status')" :name="tt('catalog_status')">
                                    <el-select class="select-danger mb--5" :placeholder="tt('catalog_status')" v-model="exportFilter.catalogFilter">
                                        <el-option class="select-danger" v-for="cs in catalogStatus" :key="cs" :label="cs" :value="cs"></el-option>
                                    </el-select>
                                </base-input> -->
                                    <!-- <base-input :label="tt('status_report')" :name="tt('status_report')">
                                    <el-select class="select-danger mb--5" :placeholder="tt('status_report')" v-model="exportFilter.statusFilter">
                                        <el-option class="select-danger" v-for="cs in catalogStatus" :key="cs" :label="cs" :value="cs"></el-option>
                                    </el-select>
                                </base-input> -->
                                    <base-input :label="tt('status_report')">
                                        <el-select class="select-danger mb--5" v-model="exportFilter.statusFilter"
                                            :placeholder="tt('status_report')" :remote-method="queryStatusReport"
                                            :loading="selectSearch.statusReport" filterable remote reserve-keyword>
                                            <el-option class="select-danger" :value="sr.code" :label="sr.code"
                                                :key="sr.code" v-for="sr in statusReportData" :selected="sr.code"
                                                ref="statusReport"></el-option>
                                        </el-select>
                                    </base-input>
                                    <base-input :label="tt('material_number_from')">
                                        <el-select class="select-danger mb--5" v-model="exportFilter.materialNumberFrom"
                                            :placeholder="tt('minimum_type_char', { total: 2 })"
                                            :remote-method="querySearchMatNum" :loading="selectSearch.matnumFromLoading"
                                            filterable remote reserve-keyword>
                                            <el-option class="select-danger" :value="matnum.material_number"
                                                :label="matnum.material_number" :key="matnum.material_number"
                                                v-for="matnum in materialNumber" :selected="matnum.materialNumber"
                                                ref="materialNumberFrom"></el-option>
                                        </el-select>
                                    </base-input>
                                    <base-input :label="tt('material_number_to')" :name="tt('material_number_to')">
                                        <el-select class="select-danger mb--5" v-model="exportFilter.materialNumberTo"
                                            :placeholder="tt('minimum_type_char', { total: 1 })"
                                            :remote-method="querySearchMatNum" :loading="selectSearch.matnumTomLoading"
                                            filterable remote reserve-keyword>
                                            <el-option class="select-danger" :value="matnum.material_number"
                                                :label="matnum.material_number" :key="matnum.material_number"
                                                v-for="matnum in materialNumber" :selected="matnum.materialNumber"
                                                ref="materialNumberTo"></el-option>
                                        </el-select>
                                    </base-input>

                                    <div class="mt-5">
                                        <base-button class="col-12 mt--3" size="large" type="primary" disabled
                                            v-if="exportFilter.statusFilter == '' && exportFilter.materialNumberFrom == '' && exportFilter.materialNumberTo == ''">Export
                                            as PDF</base-button>
                                        <a target="_blank"
                                            :href="apiUrl + 'work-report/export?statusReport=' + exportFilter.statusFilter + '&materialNumberFrom=' + exportFilter.materialNumberFrom + '&materialNumberTo=' + exportFilter.materialNumberTo + '&token=' + token"
                                            class="col-12 btn btn-primary" v-else>Export as PDF</a>
                                    </div>
                                </div>
                                <div v-if="inputType == 'Part Char Horizontal'">
                                    <base-input :label="tt('item_name')" :name="tt('item_name')">
                                        <el-select v-model="inc" :placeholder="tt('minimum_type_char', { total: 1 })"
                                            :remote-method="querySearchItemName" :loading="selectSearch.sitLoading"
                                            filterable remote reserve-keyword>
                                            <el-option v-for="si in standardItemName" :key="si.inc" :value="si.inc"
                                                :label="si.inc + ' - ' + si.item_name"></el-option>
                                        </el-select>
                                    </base-input>

                                    <div class="mt-3">
                                        <base-button class="col-12" size="large" type="primary" disabled
                                            v-if="!inc">Export as Excel</base-button>
                                        <a :href="apiUrl + 'export/export-table?inc=' + inc + '&type=' + inputType + '&token=' + token"
                                            class="col-12 btn btn-primary" v-else>Export as Excel</a>
                                        <!-- <base-button @click="download()">Download</base-button> -->
                                    </div>
                                </div>
                                <div v-if="inputType == 'Part Char Horizontal Multiple'">
                                    <base-input :label="tt('item_name')" :name="tt('item_name')">
                                        <el-select v-model="multipleInc"
                                            :placeholder="tt('minimum_type_char', { total: 1 })"
                                            :remote-method="querySearchItemName" :loading="selectSearch.sitLoading"
                                            filterable remote reserve-keyword multiple>
                                            <el-option v-for="si in standardItemName" :key="si.inc" :value="si.inc"
                                                :label="si.inc + ' - ' + si.item_name"></el-option>
                                        </el-select>
                                    </base-input>

                                    <div class="mt-3">
                                        <base-button class="col-12" size="large" type="primary"
                                            @click="importExport('Multiple')"
                                            :disabled="(multipleInc.length == 0) || (loadExport)">
                                            <span v-if="loadExport"><i class="fas fa-spinner fa-spin"></i>{{
                                                tt("please_wait") }}</span>
                                            <span v-else>Export as Excel</span>
                                        </base-button>
                                        <a v-show="exportStatus"
                                            :href="apiUrl + 'ExportDownload?file_name=' + encodeURIComponent(exportMultipleName) + '&token=' + token"
                                            class="col-12 mt-2 btn btn-primary">Download</a>
                                        <!-- <base-button @click="download()">Download</base-button> -->
                                    </div>
                                </div>
                                <div v-if="inputType == 'Part Char Horizontal by File'">
                                    <base-input :label="tt('item_name')" :name="tt('item_name')">
                                        <file-input @change="changeImport" :ref="'file'"></file-input>
                                    </base-input>

                                    <div class="mt-3">
                                        <!-- <base-button class="col-12" size="large" type="primary" disabled v-if="!inc">Export as Excel</base-button> -->
                                        <base-button class="col-12" size="large" type="primary"
                                            @click="importExport('File')"
                                            :disabled="(loadExport) || (fileImport == null)">
                                            <span v-if="loadExport"><i class="fas fa-spinner fa-spin"></i>{{
                                                tt("please_wait") }}</span>
                                            <span v-else>Export as Excel</span>
                                        </base-button>
                                        <a v-show="exportStatus"
                                            :href="apiUrl + 'ExportDownload?file_name=' + encodeURIComponent(exportMultipleName) + '&token=' + token"
                                            class="col-12 mt-2 btn btn-primary">Download</a>
                                        <!-- <a :href="apiUrl+'export/export-table?inc='+inc+'&type='+inputType+'&token='+token" class="col-12 btn btn-primary" v-else>Export as Excel</a> -->
                                        <!-- <base-button @click="download()">Download</base-button> -->
                                    </div>
                                </div>
                                <div v-if="inputType == 'Mesc Characteristic'">
                                    <base-input :name="tt('item_name')" rules="required">
                                        <el-select v-model="inc" :placeholder="tt('minimum_type_char', { total: 1 })"
                                            :remote-method="querySearchItemName" :loading="selectSearch.sitLoading"
                                            filterable remote reserve-keyword>
                                            <el-option v-for="si in standardItemName" :key="si.inc" :value="si.inc"
                                                :label="si.inc + ' - ' + si.item_name"></el-option>
                                        </el-select>
                                    </base-input>
                                    <base-button class="col-12 mt--3" size="large" type="primary" disabled
                                        v-if="!inc">Export as Excel</base-button>
                                    <a :href="apiUrl + 'MescGenerate/export?inc=' + inc + '&token=' + token"
                                        class="col-12 mt--3 btn btn-primary" v-else>Export as Excel</a>
                                </div>
                                <div v-if="inputType == 'End User' || inputType == 'Workbench'">
                                    <base-input :label="tt('data')" :name="tt('table')">
                                        <el-select class="select-danger" v-model="inputExportReport.table"
                                            :placeholder="tt('choose_data')" :disabled="disabled">
                                            <el-option class="select-danger" :value="der" :label="der" :key="der"
                                                v-for="der in dataExportReport"></el-option>
                                        </el-select>
                                    </base-input>

                                    <base-input :label="tt('company')" :name="tt('company')">
                                        <el-select class="select-danger" v-model="exportFilter.client"
                                            :placeholder="tt('choose_data')" :disabled="disabled">
                                            <el-option class="select-danger" :value="company.value"
                                                :label="company.label" :key="company.value"
                                                v-for="company in companies"></el-option>
                                        </el-select>
                                    </base-input>

                                    <base-input :label="tt('cat_status')" :name="tt('cat_status')">
                                        <el-select class="select-danger" v-model="exportFilter.cat_status"
                                            :placeholder="tt('choose_data')" :disabled="disabled">
                                            <el-option class="select-danger" :value="status" :label="status"
                                                :key="status" v-for="status in catalogStatus"></el-option>
                                        </el-select>
                                    </base-input>

                                    <base-input :label="tt('status_report')" :name="tt('status_report')">
                                        <el-select class="select-danger mb--5" v-model="exportFilter.statusFilter"
                                            :placeholder="tt('status_report')" :remote-method="queryStatusReport"
                                            :loading="selectSearch.statusReport" filterable remote reserve-keyword>
                                            <el-option class="select-danger" :value="sr.code" :label="sr.code"
                                                :key="sr.code" v-for="sr in statusReportData" :selected="sr.code"
                                                ref="statusReport"></el-option>
                                        </el-select>
                                    </base-input>

                                    <div class="mt-3">
                                        <base-button class="col-12" size="large" type="primary" disabled
                                            v-if="!(inputExportReport.table && inputType)">Export as Excel</base-button>
                                        <a :href="apiUrl + 'export/export-table?table=' + inputExportReport.table + '&type=' + inputType + '&token=' + token + '&client=' + exportFilter.client + '&cat_status=' + exportFilter.cat_status + '&status_report=' + exportFilter.statusFilter"
                                            class="col-12 btn btn-primary" v-else target="_blank">Export as Excel</a>
                                    </div>
                                </div>
                                <div v-if="inputType == 'Generate Bom Report'">
                                    <form method="post" :action="apiUrl + 'Bom/ReportGenerate?token=' + token"
                                        enctype="multipart/form-data" target="_new">
                                        <input class="form-control" type="file" id="file" name="excel">
                                        <button class="col-12 btn btn-primary mt-3">{{ tt('export') }}</button>
                                    </form>
                                </div>
                            </div>
                            <div class="col-6 text-center">
                                <img src="/assets/smartcat/export.png" alt="Data Is Empty" width="350px">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import Api from '@/helpers/api';
import baseApiUrl from '@/configs/config';
import catalogStatus from '@/services/master/catalogStatus.service';
import exportUrl from '@/services/report/export.service.js';
import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
import WorkbenchMaterial from '@/services/workbench/WorkbenchMaterial.service';
import statusReport from '@/services/master/statusReport.service';
import workReport from '@/services/report/workReport.service';
import standardItemName from '@/services/dictionary/standardItemName.service';
import companyCode from '@/services/master/companyCode.service';

export default {
    watch: {
        'exportFilter.materialNumberTo': function () {
            if (this.exportFilter.materialNumberTo < this.exportFilter.materialNumberFrom) {
                this.exportFilter.materialNumberTo = ''
            }
        }
    },
    data() {
        return {
            inputExportReport: {
                table: '',
            },
            inputType: '',
            disabled: true,
            dataExportReport: {},
            token: localStorage.token,
            apiUrl: baseApiUrl.apiUrl,
            exportInput: false,
            exportFilter: {
                catalogFilter: '',
                materialNumberTo: '',
                materialNumberFrom: '',
                statusFilter: '',
                client: '',
                cat_status: '',
                status_report: ''
            },
            catalogStatus: [],
            catalogFilter: '',
            materialNumber: [],
            statusReportData: [],
            selectSearch: {
                matnumFromLoading: false,
                matnumToLoading: false,
                statusReport: false,
                sitLoading: false,
                loadTimeout: null
            },
            pleaseWait: false,
            standardItemName: [],
            inc: '',
            multipleInc: [],
            fileImport: null,
            loadExport: false,
            exportStatus: false,
            exportMultipleName: '',
            handleDownload: false,
            companies: []
        }
    },
    computed: {
        ...mapState({
            authUserPermission: state => state.auth.userPermission
        }),
    },
    mounted() {
        this.getCatalogStatus()
        this.getCompanyCode()
        console.log(this.companies)
    },
    methods: {
        changeType(data) {
            this.disabled = false

            if (data == 'End User') {
                this.dataExportReport = ['Material Master', 'Material Characteristic Value', 'Material Manufacture Ref', 'Material Plant', 'Material Storage Location', 'Material Purchasing', 'Material Sales', 'Material MRP', 'Material Accounting', 'Material Costing', 'Material Equipment', 'Material Attachment', 'Material Keyword', 'Old Material Number', 'Material MCR']
            } else {
                this.dataExportReport = ['Material Master', 'Material Characteristic Value', 'Material Manufacture Ref', 'Material Plant', 'Material Storage Location', 'Material Equipment', 'Material Attachment', 'Material Keyword', 'Old Material Number']
            }
        },
        getCatalogStatus() {
            let context = this;
            Api(context, catalogStatus.getByUser(null, { per_page: 'none' })).onSuccess(function (response) {
                context.catalogStatus = response.data.data.data;
            }).onError(function (error) {
                if (error.response.status == 404) {
                    context.catalogStatus = [];
                }
            })
                .call()
        },
        querySearchMatNum(query) {
            if (query.length > 2) {
                clearTimeout(this.selectSearch.loadTimeout);
                let context = this;
                this.pleaseWait = true;
                this.selectSearch.matnumFromLoading = true;
                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, WorkbenchMaterial.getMaterialNumber({ search: query }))
                        .onSuccess(function (response) {
                            context.materialNumber = response.data.data;
                            context.selectSearch.matnumFromLoading = false
                        }).onError(function (error) {
                            if (error.response.status == 404) {
                                context.materialNumber = [];
                                context.selectSearch.matnumFromLoading = false
                            }
                        }).call()
                })
            }
        },
        queryStatusReport(query) {
            if (query.length >= 1) {
                clearTimeout(this.selectSearch.loadTimeout);
                let context = this;
                this.pleaseWait = true;
                this.selectSearch.statusReport = true;
                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, statusReport.get({ search: query })).onSuccess((response) => {
                        context.statusReportData = response.data.data
                    }).onError((error) => {
                        context.statusReportData = [];
                    }).onFinish(() => {
                        context.selectSearch.statusReport = false;
                    }).call()
                })
            }
        },
        querySearchItemName(query) {
            if (query.length > 3) {
                clearTimeout(this.selectSearch.loadTimeout);
                let context = this;
                this.selectSearch.sitLoading = true;

                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, standardItemName.get(null, { per_page: 'none', item_name: query })).onSuccess(function (response) {
                        context.standardItemName = response.data.data.data.data;
                        context.selectSearch.sitLoading = false;
                    }).onError(function (error) {
                        context.standardItemName = [];
                        context.selectSearch.sitLoading = false;
                    }).call();
                }, 200)
            }
        },
        async download() {
            for (var i = 0; i < this.dataInc.length; i++) {
                let params = {
                    inc: this.dataInc[i],
                    type: this.inputType,
                    token: this.token
                }
                statusReport.download(params).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }));

                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', this.dataInc[i]);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
            }
        },
        exports(inc) {
            var fileLink = document.createElement('a');
            fileLink.href = this.apiUrl + 'export/export-table?inc=' + inc + '&type=' + this.inputType + '&token=' + this.token;
            document.body.appendChild(fileLink);
            fileLink.click();
        },
        changeImport(files) {
            this.fileImport = files[0]
        },
        importExport(type) {
            let formData = new FormData();
            let context = this;
            this.exportStatus = false;
            this.handleDownload = false;
            if (type == 'Multiple') {
                if (this.multipleInc.length > 0) {
                    this.multipleInc.forEach(function (item, index) {
                        formData.append('inc[' + index + ']', item)
                    })
                }
            } else {
                // if(this.fileImport != null){
                formData.append('excel', this.fileImport)
                // }
            }

            this.loadExport = true;
            Api(context, workReport.import_report(formData)).onSuccess((response) => {
                context.$notify({
                    message: response.data.message,
                    type: "success",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
                context.exportStatus = !context.exportStatus
                context.exportMultipleName = response.data.data
            }).onError((err) => {
                context.$notify({
                    message: context.tt('export_error'),
                    type: "danger",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            }).onFinish(() => {
                context.loadExport = false
            }).call()
        },
        getCompanyCode() {
            let context = this;
            Api(context, companyCode.get(null, { per_page: 'none' })).onSuccess(function (response) {
                response.data.data.data.data.forEach(function (item, index) {
                    context.companies[index] = {
                        'value': item['company_code'],
                        'label': item['company_code'] + " - " + item['company_description']
                    };
                });
            }).onError(function (error) {
                if (error.response.status == 404) {
                    context.companies = [];
                }
            }).call()
        },
        // workExport(){
        //     let formData = new FormData();
        //     let context = this;
        //     let data = {};
        //     this.pleaseWait = true;
        //     if(this.exportFilter.materialNumberFrom == ''){
        //         data.catalogStatus = this.exportFilter.catalogFilter;
        //     }else{
        //         data.catalogStatus = this.exportFilter.catalogFilter;
        //         data.materialNumberFrom = this.exportFilter.materialNumberFrom;
        //         if(this.exportFilter.materialNumberTo != ''){
        //             data.materialNumberTo = this.exportFilter.materialNumberTo;
        //         }
        //     }
        //     Api(context, exportUrl.work_export(data)).onSuccess((response) =>{
        //        context.$notify({
        //             message: 'Successfully',                  
        //             type: 'success',
        //             verticalAlign: "bottom",
        //             horizontalAlign: "left"
        //         });
        //     }).onError((error) => {
        //         context.$notify({
        //             message: context.tt('error_message'),                
        //             type: 'danger',
        //             verticalAlign: "bottom",
        //             horizontalAlign: "left"
        //         });
        //     }).onFinish(() =>{
        //         context.pleaseWait = true;
        //     }).call()
        // }
    }
};
</script>
<style></style>
